@import "../../../../styles/Colors.scss";

.share-icon-popup-style {
  position: absolute;
  top: -8px;
  width: 120px;
  right: 30px;
  z-index: 1;
  border-radius: 3px;
  padding: 5px 8px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background-color: #fff;
}

.copied-text-dialog {
  font-size: 13px;
}

.small-icon {
  font-size: 18px !important;
}
.priceCut {
  color: #606060;
  letter-spacing: 0px;
  text-decoration: line-through;
  font-family: "ZemestroStd-Light" !important;
  font-weight: 100 !important;
}
.ProductPage
  .ProductDetailPanel
  .MobileQuantityPicker.MobileQuantityPickerSoldOut
  .ant-input-number-wrapper
  .ant-input-number {
  width: 35px !important;
  margin: 0 10px !important;
  border: 1px solid #e2e5f1;
  border-radius: 8px !important;
}

.ProductPage .ProductDetailPanel .price.priceItemMobile {
  font-size: 16px !important;
}

.mobilePriceRed {
  color: #ff0d50 !important;
}

@keyframes addToCartAnimation {
  9% {
    background-image: url("../../../../assets/images/addToCartAnimation/animation-1.png");
  }

  18% {
    background-image: url("../../../../assets/images/addToCartAnimation/animation-2.png");
  }

  27% {
    background-image: url("../../../../assets/images/addToCartAnimation/animation-3.png");
  }

  36% {
    background-image: url("../../../../assets/images/addToCartAnimation/animation-4.png");
  }

  45% {
    background-image: url("../../../../assets/images/addToCartAnimation/animation-5.png");
  }

  54% {
    background-image: url("../../../../assets/images/addToCartAnimation/animation-6.png");
  }

  63% {
    background-image: url("../../../../assets/images/addToCartAnimation/animation-7.png");
  }

  72% {
    background-image: url("../../../../assets/images/addToCartAnimation/animation-8.png");
  }

  81% {
    background-image: url("../../../../assets/images/addToCartAnimation/animation-9.png");
  }

  90% {
    background-image: url("../../../../assets/images/addToCartAnimation/animation-10.png");
  }

  100% {
    background-image: url("../../../../assets/images/addToCartAnimation/animation-11.png");
  }
}

body .add-to-cart-button-animation {
  // background-color: red;
  // background-color: #E72744;
  background-image: url("../../../../assets/images/addToCartAnimation/animation-1.png");
  animation-name: addToCartAnimation;
  animation-timing-function: ease-in;
  animation-duration: 2s;
  height: 37px;
  border-radius: 3px;
  width: 137px;
  background-size: contain;
  background-repeat: no-repeat;
}
body .ant-input-number-readonly {
  border: none !important;
  .ant-input-number-input-wrap {
    input {
      font-size: 20px !important;
    }
  }
}
body .ant-input-number-group-addon {
  // background-color: var(--background-color);
  border-radius: 10px !important;
  span {
    color: #fff !important;
    font-size: 25px;
  }
}
.ant-input-number-input-wrap {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  input {
    text-align: center !important;
    padding: 10px 0px !important;
  }
}
body .quantity-add-to-cart-desktop {
  flex-wrap: nowrap;
}
.add-to-cart-button span {
  margin-top: 0px;
}
.qr-code svg {
  width: 100px;
  height: 100px;
}
body .anticon-exclamation-circle svg {
  position: relative;
  top: -2px;
}
.extra-details input::placeholder {
  color: #bfbfbf;
  font-weight: 100;
}

@media (min-width: 768px) {
  .extra-details {
    width: 60%;
  }
}
@media (max-width: 767px) {
  .extra-details {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .extra-details > div {
    max-width: 80%;
  }
  .extra-details-inner input {
    margin: 0;
  }
}

.green-fusion-cart-text {
  margin-top: 20px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  padding: 0 5px;
  display: inline-block;
}

.green-fusion-cart-text .green-fusion-link {
  color: var(--background-color) !important;
  font-weight: bold;
}

@media screen and (max-width: 767px) {
  .green-fusion-cart-text {
    margin-top: 0px;
    box-shadow: none;
    padding: 0 5px;
    display: block;
    width: 100%;
    text-align: center;
    .green-fusion-link {
      margin-left: 5px;
      color: var(--background-color) !important;
      font-weight: bold;
    }
    p {
      font-size: 14px !important;
      margin-right: 2px;
    }
  }
}
